import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangeTypeChat from "./ChangeTypeChat";
import ChangeAssistant from "./ChangeAssistant";
import ChangeMachine from "./ChangeMachine";
import {
  IconBolt,
  IconLoader2,
  IconSettings,
  IconX,
} from "@tabler/icons-react";
import {
  useCreateNewChannel,
  useGetConfigsMainChat,
  useGetIsLogged,
} from "../../../../hooks/smallHooks";
import useSendMessage from "../../../../hooks/useSendMessage";
import { setOpenLogin } from "../../../../redux/general/action";
import AttachButton from "./ButtonActions/AttachButton";
import AudioRecorderButton from "./ButtonActions/AudioRecorderButton";
import SendButton from "./ButtonActions/SendButton";
import ModalGlobal from "../../../Modals/ModalGlobal";
import COLORS from "../../../../utils/constants/colors";
import BREAKPOINTS from "../../../../utils/constants/breakpoints";
import Tooltip from "../../../Add-ons/Tooltip";
import { useNavigate } from "react-router-dom";
import ModalAttachFile from "../../../Modals/ModalAttachFile";
import { truncateNumber } from "../../../../utils/stringutils";
import useTranslation from "../../../../hooks/useTranslation";
import { PageChatI18n } from "../../../Add-ons/PageChat.i18n";
import useUserCredits from "../../../../hooks/useUserCredits";
import { UserSubscription } from "../../../Cards/UserSubscription";

const InputMainChat = () => {
  const dispatch = useDispatch();

  const [question, setQuestion] = useState("");

  const [isProcessingAudio, setIsProcessingAudio] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [showModalAttachFile, setShowModalAttachFile] = useState(false);

  const { t } = useTranslation(PageChatI18n);

  const textareaRef = useRef(null);
  const navigate = useNavigate();

  const isLogged = useGetIsLogged();
  const state = useSelector(state => state);
  const configsMainChat = useGetConfigsMainChat();

  const { handleCreateChannel: createChannel } = useCreateNewChannel();
  const {
    sendMessage,
    isReceivingMessage,
    disableCancelMessage,
    cancelMessage,
  } = useSendMessage();

  const {
    hasSufficientCredits,
    isLoadingCredits,
    credits,
    hasSubscriptionActive,
  } = useUserCredits();

  const isSubscriptionActive = hasSubscriptionActive();
  const isFreeModel = configsMainChat?.machine?.includes(":free");

  // const handleSubmitAudio = async transcription => {
  //   if (!isFreeModel && !hasSufficientCredits()) {
  //     return;
  //   }

  //   if (isReceivingMessage) {
  //     cancelMessage();
  //   } else if (configsMainChat?.chatData) {
  //     sendMessage(transcription, true);
  //   } else {
  //     setQuestion(transcription);
  //     await createChannel();
  //     setTimeout(() => {
  //       const sendButton = document.querySelector("#btn-send-message");
  //       if (sendButton) {
  //         sendButton.click();
  //       }
  //     }, 300);
  //   }
  // };

  const handleSendButtonClick = async e => {
    if (!isLogged) {
      dispatch(setOpenLogin(true));
      return;
    }
    e.preventDefault();

    if (!isFreeModel && !hasSufficientCredits() && !isSubscriptionActive) {
      return;
    }

    if (isReceivingMessage) {
      cancelMessage();
    } else if (configsMainChat?.chatData) {
      sendMessage(question);
      setQuestion("");
    } else {
      await createChannel();
      setTimeout(() => {
        const sendButton = document.querySelector("#btn-send-message");
        if (sendButton) {
          sendButton.click();
        }
      }, 300);
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        400
      )}px`;
    }
  }, [question]);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        400
      )}px`;
    }
  };

  const handleChange = e => {
    setQuestion(e.target.value);
    adjustHeight();
  };

  const handleKeyDown = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendButtonClick(e);
    }
  };

  const isMobile = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.MD;

  const toggleModal = () => {
    setIsOpenModal(true);
  };

  return (
    <>
      {!isMobile && (
        <form action="" className="d-flex flex-column p-3" style={{ gap: 12 }}>
          <textarea
            ref={textareaRef}
            autoFocus={true}
            name="input-main-chat"
            id="input-main-chat"
            placeholder="Digite aqui..."
            autoComplete="off"
            value={question}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <div className="group-buttons">
            <div className="selectors">
              <ChangeAssistant
                background={"#fff"}
                state={state}
                dispatch={dispatch}
              />
              <ChangeMachine background={"#fff"} state={state} />

              <ChangeTypeChat />

              {/* <div className="d-flex align-items-center gap-2">
                <Tooltip text={textTooltip} key={""} children={""}>
                  <span className="small">Search on Web</span>
                </Tooltip>
                <Switch
                  size="small"
                  style={{
                    backgroundColor: configsMainChat?.enableWebSearch
                      ? "#000"
                      : "",
                  }}
                  onChange={checked => {
                    dispatch(
                      setConfigsMainChat(
                        KEYS_MAIN_CHAT.enableWebSearch,
                        checked
                      )
                    );
                  }}
                  defaultChecked={configsMainChat?.enableWebSearch}
                />
              </div> */}
            </div>
            <div className="action-buttons">
              {isLogged && (
                <div className="ms-2">
                  {isLoadingCredits ? (
                    <IconLoader2 size={16} className="spin" />
                  ) : (
                    <div className="d-flex align-items-center gap-1">
                      {isSubscriptionActive ? (
                        <UserSubscription />
                      ) : (
                        <>
                          {credits &&
                          typeof credits.amount === "number" &&
                          credits.amount > 0 ? (
                            <Tooltip
                              children={
                                <strong className="d-flex align-items-center gap-1">
                                  {truncateNumber(credits.amount, 2).toFixed(2)}{" "}
                                  <IconBolt size={18} />
                                </strong>
                              }
                              text={t("tooltip.credits.your")}
                              action={{
                                name: t("tooltip.credits.buy"),
                                execute: () => {
                                  navigate("/settings?tab=credits");
                                },
                              }}
                            />
                          ) : (
                            <Tooltip
                              children={
                                <strong className="d-flex align-items-center gap-1">
                                  0 <IconBolt size={18} />
                                </strong>
                              }
                              text={t("tooltip.credits.your")}
                              action={{
                                name: t("tooltip.credits.buy"),
                                execute: () => {
                                  navigate("/settings?tab=credits");
                                },
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}

              <AttachButton
                onClick={() => {
                  isLogged
                    ? setShowModalAttachFile(true)
                    : dispatch(setOpenLogin(true));
                }}
              />
              {/* <AudioRecorderButton
                onStopRecorder={handleSubmitAudio}
                onAudioProcessing={setIsProcessingAudio}
              /> */}
              {!isProcessingAudio && (
                <SendButton
                  isReceivingMessage={isReceivingMessage}
                  disableCancelMessage={disableCancelMessage}
                  onClick={handleSendButtonClick}
                />
              )}
            </div>
            <ModalAttachFile
              setShowModalAttachFile={setShowModalAttachFile}
              showModalAttachFile={showModalAttachFile}
            />
          </div>
        </form>
      )}
      <>
        {isMobile && (
          <form
            action=""
            className="d-flex flex-column mb-1 p-2"
            style={{ gap: 6 }}
          >
            <div className="d-flex input-main-chat-mobile">
              <textarea
                ref={textareaRef}
                autoFocus={true}
                name="input-main-chat"
                id="input-main-chat"
                placeholder="Digite aqui..."
                autoComplete="off"
                value={question}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <div className="action-buttons">
                {!isProcessingAudio && (
                  <SendButton
                    isReceivingMessage={isReceivingMessage}
                    disableCancelMessage={disableCancelMessage}
                    onClick={handleSendButtonClick}
                  />
                )}
              </div>
            </div>
            <div className="group-buttons">
              <div className="selectors w-100 justify-content-between">
                <div className="media-send-actions-mobile d-flex align-items-center gap-2">
                  <ChangeTypeChat />
                  <div className="d-flex align-items-center gap-2">
                    <AttachButton
                      onClick={() => setShowModalAttachFile(true)}
                    />
                    {/* <AudioRecorderButton
                      onStopRecorder={handleSubmitAudio}
                      onAudioProcessing={setIsProcessingAudio}
                    /> */}
                  </div>
                </div>
                <div>
                  <div
                    className="change-settings-mobile d-flex px-2 align-items-center justify-content-center"
                    onClick={toggleModal}
                    style={{
                      background: "#fff",
                      height: 40,
                      borderRadius: 8,
                      width: 40,
                    }}
                  >
                    <IconSettings size={20} />
                  </div>
                  <ModalGlobal
                    open={isOpenModal}
                    showBtnClose={false}
                    classNameChildren={
                      "p-4 animate__animated animate__fadeInDown animate__faster"
                    }
                    style={{ height: "calc(100% - 60px)" }}
                  >
                    <div className="w-100 h-100 d-flex flex-column gap-3">
                      <div className="d-flex justify-content-end mb-2">
                        <button
                          style={{
                            background: COLORS.gray100,
                            width: 30,
                            height: 30,
                            borderRadius: 8,
                          }}
                          onClick={() => setIsOpenModal(false)}
                        >
                          <IconX
                            stroke={1.5}
                            style={{ width: 16, height: 16 }}
                          />
                        </button>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <span>{t("assistants")}</span>
                        <div>
                          <ChangeAssistant
                            background={COLORS.gray100}
                            state={state}
                          />
                        </div>
                      </div>
                      <div className="divider"></div>
                      <div className="d-flex flex-column gap-2">
                        <span>{t("models")}</span>
                        <ChangeMachine
                          background={COLORS.gray100}
                          state={state}
                        />
                      </div>
                    </div>
                  </ModalGlobal>
                </div>
              </div>
              <ModalAttachFile
                setShowModalAttachFile={setShowModalAttachFile}
                showModalAttachFile={showModalAttachFile}
              />
            </div>
          </form>
        )}
      </>
    </>
  );
};

export default InputMainChat;
