/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import {
  IconChevronLeftPipe,
  IconLoader2,
  IconPlus,
  IconRobotFace,
  IconSearch,
  IconSettings,
  IconTrash,
} from "@tabler/icons-react";
import GroupListChats from "./GroupListChats";
import {
  useCreateNewChannel,
  useGetIsLogged,
} from "../../../../hooks/smallHooks";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../../../libs/react-query";
import { getChannelsAndDocuments } from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenLeftBar,
  setOpenLeftBarMobile,
  setOpenLogin,
} from "../../../../redux/general/action";
import BREAKPOINTS from "../../../../utils/constants/breakpoints";
import {
  subDays,
  startOfDay,
  endOfDay,
  isAfter,
  isBefore,
  isToday,
} from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonAsideType1 from "../../../Buttons/ButtonAsideType1";
import useDeleteAllChats from "../../../../utils/deleteAllChats";
import { AizzyLogo } from "../../../AizzyLogo";
import { AizzySmallLogo } from "../../../AizzySmallLogo";
import useTranslation from "../../../../hooks/useTranslation";
import { SideMenuType2I18n } from "./SideMenuType2.i18n";

const SideMenuType2 = () => {
  const { handleCreateChannel, isCreating } = useCreateNewChannel();
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const [searchChats, setSearchChats] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(SideMenuType2I18n);

  const { handleDeleteAllChats } = useDeleteAllChats();

  const sizeIcon =
    state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.LG ? 20 : 17;

  const isMobile = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  const logged = useGetIsLogged();

  const { data: channels, isLoading } = useQuery(
    QUERY_KEYS.CHANNELS,
    async () => {
      const data = await getChannelsAndDocuments();
      return data.channels ?? [];
    },
    {
      staleTime: 180 * 60 * 1000,
      enabled: logged,
    }
  );

  const channelDetails = useMemo(() => {
    if (!Array.isArray(channels)) return [];

    return channels.map(
      ({ _id, userId, channelName, assistantId, created_at, updated_at }) => ({
        _id,
        userId,
        channelName,
        assistantId,
        createdAt: created_at,
        updatedAt: updated_at,
      })
    );
  }, [channels]);

  const filteredChannels = useMemo(() => {
    if (searchChats.length > 0) {
      return channelDetails.filter(chat =>
        chat.channelName.toLowerCase().includes(searchChats.toLowerCase())
      );
    }
    return channelDetails;
  }, [searchChats, channelDetails]);

  const groupedChats = useMemo(() => {
    const now = new Date();
    const startOfToday = startOfDay(now);
    const startOfYesterday = startOfDay(subDays(now, 1));
    const endOfLast7Days = endOfDay(subDays(now, 7));
    const startOfLast30Days = startOfDay(subDays(now, 30));

    const todayChats = channelDetails.filter(chat =>
      isToday(new Date(chat.createdAt))
    );

    const yesterdayChats = channelDetails.filter(
      chat =>
        isAfter(new Date(chat.createdAt), startOfYesterday) &&
        isBefore(new Date(chat.createdAt), startOfToday)
    );

    const last7DaysChats = channelDetails.filter(
      chat =>
        isAfter(new Date(chat.createdAt), endOfLast7Days) &&
        isBefore(new Date(chat.createdAt), startOfYesterday)
    );

    const last30DaysChats = channelDetails.filter(
      chat =>
        isAfter(new Date(chat.createdAt), startOfLast30Days) &&
        isBefore(new Date(chat.createdAt), endOfLast7Days)
    );

    const previousChats = channelDetails.filter(chat =>
      isBefore(new Date(chat.createdAt), startOfLast30Days)
    );

    return {
      todayChats,
      yesterdayChats,
      last7DaysChats,
      last30DaysChats,
      previousChats,
    };
  }, [channelDetails]);

  useEffect(() => {
    if (state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG) {
      dispatch(setOpenLeftBar(true));
    }
  }, [dispatch, state.configsGeneralReducer.windowWidth]);

  const handleSidebarToggle = () => {
    if (isMobile) {
      dispatch(
        setOpenLeftBarMobile(!state?.configsGeneralReducer?.openLeftBarMobile)
      );
    } else {
      dispatch(setOpenLeftBar(!state?.configsGeneralReducer?.openLeftBar));
    }
  };

  const handleOutsideClick = event => {
    if (
      !event.target.closest(".side-menu-type-2") &&
      !event.target.closest(".menu-btn")
    ) {
      event.stopPropagation("menu-btn");
      dispatch(setOpenLeftBarMobile(false));
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dispatch]);

  const deleteChats = async () => {
    setLoading(true);
    await handleDeleteAllChats();
    setLoading(false);
    isMobile && dispatch(setOpenLeftBarMobile(false));
  };

  return (
    <aside
      className={`side-menu-type-2 ${!state?.configsGeneralReducer?.openLeftBar ? "minimize" : ""
        } ${state?.configsGeneralReducer?.openLeftBarMobile === true
          ? "d-flex animate__animated animate__fadeInLeft animate__faster"
          : isMobile
            ? "d-flex animate__animated animate__fadeOutLeft animate__faster"
            : "d-flex"
        }`}
    >
      {!state?.configsGeneralReducer?.openLeftBar && (
        <button
          onClick={() => {
            navigate("/");
            isMobile && dispatch(setOpenLeftBarMobile(false));
          }}
          className="pointer-event"
        >
          <AizzySmallLogo />
        </button>
      )}
      <div className="d-flex justify-content-between align-items-center">
        {state?.configsGeneralReducer?.openLeftBar && (
          <button
            onClick={() => {
              navigate("/");
              isMobile && dispatch(setOpenLeftBarMobile(false));
            }}
            className="pointer-event"
          >
            <AizzyLogo />
          </button>
        )}
        <button
          className={"btn-open-bar"}
          style={{
            alignSelf: state?.configsGeneralReducer?.openLeftBar
              ? "flex-end"
              : "center",
            padding: state?.configsGeneralReducer?.openLeftBar ? "0 12px" : "0",
          }}
          onClick={
            !state?.configsGeneralReducer?.openLeftBar
              ? handleSidebarToggle
              : null
          }
        >
          <div
            className="icon-open-bar d-flex justify-content-center align-items-center"
            style={{
              borderRadius: 4,
              width: 24,
              height: 24,
            }}
            onClick={handleSidebarToggle}
          >
            <IconChevronLeftPipe
              style={{
                width: 16,
                height: 16,
                transition: "all .2s",
                transform: `rotate(${state?.configsGeneralReducer?.openLeftBar ? 0 : 180
                  }deg)`,
              }}
            />
          </div>
        </button>
      </div>

      {!state?.configsGeneralReducer?.openLeftBar && (
        <div className={"divider"}></div>
      )}
      <ButtonAsideType1
        dark
        onClick={async () => {
          if (logged) {
            isMobile && dispatch(setOpenLeftBarMobile(false));
            await handleCreateChannel();

            if (location.pathname !== "/") {
              navigate("/");
            }
          } else {
            isMobile && dispatch(setOpenLeftBarMobile(false));
            dispatch(setOpenLogin(true));
          }
        }}
        disabled={isCreating}
        props={{
          style: {
            justifyContent: state?.configsGeneralReducer?.openLeftBar
              ? "flex-start"
              : "center",
          },
        }}
      >
        {isCreating ? (
          <>
            <IconLoader2 stroke={1} size={sizeIcon} className="spin" />
            <span className="one-line">Creating...</span>
          </>
        ) : (
          <>
            <IconPlus stroke={1} size={sizeIcon} style={{ minWidth: 20 }} />
            {state?.configsGeneralReducer?.openLeftBar && (
              <span className="one-line font-size-14">
                {t("header.button")}
              </span>
            )}
          </>
        )}
      </ButtonAsideType1>
      <ButtonAsideType1
        props={{
          style: {
            paddingTop: 11,
            paddingBottom: 11,
            backgroundColor: "#fcfcfd",
            justifyContent: state?.configsGeneralReducer?.openLeftBar
              ? "flex-start"
              : "center",
          },
          className: "button-search-aside",
        }}
        onClick={() => dispatch(setOpenLeftBar(true))}
      >
        <IconSearch stroke={2} size={sizeIcon} style={{ minWidth: 20 }} />
        {state?.configsGeneralReducer?.openLeftBar && (
          <input
            type="text"
            name="search-chats"
            style={{ border: "none", outline: "none", borderRadius: 8 }}
            placeholder={t("header.search")}
            id="search-chats"
            onChange={e => setSearchChats(e.target.value)}
          />
        )}
      </ButtonAsideType1>
      {isLoading ? (
        <div className="mt-4 w-100 d-flex justify-content-center">
          <IconLoader2 stroke={2} size={sizeIcon} className="spin" />
        </div>
      ) : (
        state?.configsGeneralReducer?.openLeftBar && (
          <>
            {searchChats.length > 0 ? (
              <GroupListChats
                title={t("header.searchResults")}
                chats={filteredChannels}
              />
            ) : (
              <div className="d-flex flex-column gap-2 list-chats">
                {groupedChats.todayChats.length > 0 && (
                  <GroupListChats
                    title={
                      state?.configsGeneralReducer?.openLeftBar
                        ? t("groupList.today")
                        : null
                    }
                    chats={groupedChats.todayChats}
                  />
                )}
                {groupedChats.yesterdayChats.length > 0 && (
                  <GroupListChats
                    title={
                      state?.configsGeneralReducer?.openLeftBar
                        ? t("groupList.yesterday")
                        : null
                    }
                    chats={groupedChats.yesterdayChats}
                  />
                )}
                {groupedChats.last7DaysChats.length > 0 && (
                  <GroupListChats
                    title={
                      state?.configsGeneralReducer?.openLeftBar
                        ? t("groupList.lastDays")
                        : null
                    }
                    chats={groupedChats.last7DaysChats}
                  />
                )}
                {groupedChats.last30DaysChats.length > 0 && (
                  <GroupListChats
                    title={
                      state?.configsGeneralReducer?.openLeftBar
                        ? t("groupList.last30Days")
                        : null
                    }
                    chats={groupedChats.last30DaysChats}
                  />
                )}
                {groupedChats.previousChats.length > 0 && (
                  <GroupListChats
                    title={
                      state?.configsGeneralReducer?.openLeftBar
                        ? "Previous"
                        : null
                    }
                    chats={groupedChats.previousChats}
                  />
                )}
              </div>
            )}
          </>
        )
      )}
      <div className={"footer-aside-nav"}>
        <div className="divider" style={{ height: 1 }}></div>
        <ButtonAsideType1
          hover
          props={{ className: "d-flex justify-content-between" }}
          onClick={() => {
            if (logged) {
              navigate("/assistants");
              dispatch(setOpenLeftBarMobile(false));
            } else {
              dispatch(setOpenLogin(true));
            }
          }}
        >
          {state?.configsGeneralReducer?.openLeftBar && (
            <span
              className="one-line"
              style={{
                fontSize: 14,
              }}
            >
              {t("footer.assistants")}
            </span>
          )}
          <IconRobotFace
            stroke={1.8}
            size={sizeIcon}
            style={{ minWidth: 20 }}
          />
        </ButtonAsideType1>
        <ButtonAsideType1
          hover
          props={{ className: "d-flex justify-content-between" }}
          onClick={() => {
            if (logged) {
              navigate("/settings");
              dispatch(setOpenLeftBarMobile(false));
            } else {
              dispatch(setOpenLogin(true));
            }
          }}
        >
          {state?.configsGeneralReducer?.openLeftBar && (
            <span
              className="one-line"
              style={{
                fontSize: 14,
              }}
            >
              {t("footer.settings")}
            </span>
          )}
          <IconSettings stroke={1.8} size={sizeIcon} style={{ minWidth: 20 }} />
        </ButtonAsideType1>
        <ButtonAsideType1
          hover
          onClick={() => {
            if (logged) {
              deleteChats();
            } else {
              dispatch(setOpenLogin(true));
            }
          }}
          props={{
            className: loading
              ? "d-flex justify-content-center"
              : "d-flex justify-content-between",
          }}
        >
          {loading ? (
            <IconLoader2 stroke={1.8} size={sizeIcon} className="spin" />
          ) : (
            <>
              {state?.configsGeneralReducer?.openLeftBar && (
                <span
                  className="one-line"
                  style={{
                    fontSize: 14,
                  }}
                >
                  {t("footer.deleteAllChats")}
                </span>
              )}
              <IconTrash
                stroke={1.8}
                size={sizeIcon}
                style={{ minWidth: 20 }}
              />
            </>
          )}
        </ButtonAsideType1>
      </div>
    </aside>
  );
};

export default SideMenuType2;
