import imgWhats from "../../../assets/images/icons-new-layout/whats.svg";
import discord from "../../../assets/images/icons-new-layout/discord.svg";
import email from "../../../assets/images/icons-new-layout/EnvelopeOpenEmail.svg";
import imgBr from "../../../assets/images/icons/survey/flag-br.svg";
import imgEn from "../../../assets/images/icons/survey/flag-eus.svg";
import imgEs from "../../../assets/images/icons/survey/flag-es.svg";
import { Link } from "react-router-dom";
import { showSuccessNotification } from "../../../services/notifications";
import useTranslation from "../../../hooks/useTranslation";
import { PageSettingsI18n } from "./PageSettings.i18n";

const SupportSettings = () => {
const { t } = useTranslation(PageSettingsI18n);

  function handleCopyEmail() {
    navigator.clipboard.writeText("support@aizzy.ai");
  }

  const cardsSupport = [
    {
      id: "suport_br",
      title: "Whatsapp",
      description: "AizzyFale",
      link: "https://chat.whatsapp.com/Evt1O26vsyt5ulXjAGo28S",
      icon: imgBr,
      img: imgWhats,
    },
    {
      id: "suport_en",
      title: "Whatsapp",
      description: "AizzyTalk",
      link: "https://chat.whatsapp.com/BrsIuQfRlqN7hyaKTv4PUx",
      icon: imgEn,
      img: imgWhats,
    },
    {
      id: "suport_es",
      title: "Whatsapp",
      description: "AizzyHable",
      link: "https://chat.whatsapp.com/KAWSdBPCG8TAw2aE0oscgZ",
      icon: imgEs,
      img: imgWhats,
    },
    {
      id: "suport_email",
      title: "Maill",
      description: "support@aizzy.ai",
      onClick: handleCopyEmail,
      img: email,
    },
  ];

  const cardCommunity = [
    {
      id: "community_br",
      title: "Discord",
      description: "Aizzy.ia",
      link: "https://discord.com/invite/YCwY4GtdWv",
      img: discord,
    },
  ];

  return (
    <div className="d-flex flex-column gap-4">
      <h4>{t("support.title")}</h4>
      <div className="d-flex flex-column gap-3">
        <h5>{t("support.support")}</h5>
        <div className="d-flex gap-3 flex-wrap justify-content-between">
          {cardsSupport?.map(details => (
            <Link
              target="_blank"
              to={details.link && details.link}
              className={"support-settings"}
              key={details.id}
              onClick={
                details.onClick
                  ? e => {
                    e.preventDefault();
                    details.onClick();
                    showSuccessNotification("Email copied");
                  }
                  : undefined
              }
            >
              <img src={details.img} alt={""} />
              <div className="details">
                <h5 className="m-0">{details.title}</h5>
                <div className="d-flex align-items-center justify-content-between gap-3">
                  <div className="d-flex align-items-center gap-2">
                    <span>{details.description}</span>
                    {details.icon && (
                      <img src={details.icon} width={16} height={16} />
                    )}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="d-flex flex-column gap-3">
        <h5>{t("support.community")}</h5>
        <div className="d-flex gap-3">
          {cardCommunity?.map(details => (
            <Link
              target="_blank"
              to={details.link}
              className={"support-settings"}
              key={details.id}
            >
              <img src={details.img} alt={""} />
              <div className="details">
                <h5 className="m-0">{details.title}</h5>
                <div className="d-flex align-items-center justify-content-between gap-3">
                  <div className="d-flex align-items-center gap-2">
                    <span>{details.description}</span>
                    {details.icon && (
                      <img src={details.icon} width={16} height={16} />
                    )}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SupportSettings;
