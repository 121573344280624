import { title } from "process";

export const PageSettingsI18n = {
  "pt-BR": {
    title: "Potencialize Sua Jornada",
    menu: {
      general: "Geral",
      security: "Segurança",
      credits: "Créditos",
      affiliation: "Afiliação",
      support: "Suporte",
    },
    buttons: {
      logout: "Sair",
      deleteAllChats: "Excluir todos os chats",
      deleteAccount: "Excluir conta",
      pix: "Copiar código Pix",
    },
    general: {
      personalInfo: {
        title: "Informações pessoais",
        fullName: "Nome Completo",
        email: "Email",
        user: "Usuário",
      },
      preferences: {
        title: "Preferências",
        language: "Idioma",
      },
      system: {
        title: "Sistema",
        activeSession: "Sessão Ativa",
        chatHistory: "Histórico de Conversas",
        deleteAccount: "Excluir Conta",
      },
    },
    security: {
      title: "Alterar senha",
      newPassword: "Nova senha",
      confirmPassword: "Confirmar senha",
      buttonSave: "Salvar",
    },
    credits: {
      title: "Créditos",
      invoice: "Histórico de faturas",
      buyCredits: "Comprar créditos",
      pix: "Adicione créditos de forma rápida e fácil escaneando o QR CODE abaixo"
    },
    affiliation: {
      affiliateProgram: {
        title: "Programa de afiliação",
        subtitle: "Apresentando nosso programa de afiliação",
        description:
          "Como afiliado, você terá a oportunidade de compartilhar nossa IA com sua rede. E a melhor parte? Você receberá uma comissão de 10% para cada inscrição feita através do seu link exclusivo.",
        question: "Como funciona?",
        stepOne: "1. Copie seu link de afiliado",
        stepTwo:
          "2. Compartilhe-o em suas redes sociais, blog, websites ou qualquer canal",
        stepThree:
          "3. Quanto mais inscrições forem feitas por meio deles, maior será a sua receita!",
        button: "Obter meu link de afiliado",
        terms:
          "Ao usar o link de afiliado, você concorda com os termos e condições do nosso programa de afiliação. Para mais informações, consulte nossa Política de Privacidade.",
      },
    },
    taximeter: {
      title: "Taxímetro",
      subtitle: "Apresentando nosso programa de afiliação",
      description:
        "Como afiliado, você terá a oportunidade de compartilhar nossa IA com sua rede. E a melhor parte? Você receberá uma comissão de 10% para cada inscrição feita através do seu link exclusivo.",
    },
    support: {
      title: "Obter ajuda",
      community: "Comunidade",
      support: "Suporte",
    },
    modals: {
      credits: {
        label: "Comprar créditos",
        method: {
          title: "Selecione o método de pagamento",
          card: "Cartão",
          aiz: "Deve ter aproximadamente",
          aizContinue: "tokens para completar esta transação.",
        },
        value: {
          title: "Valor dos créditos",
        },
        button: {
          pay: "Pagar com", 
          cancel: "Cancelar",
        },
        textOrderSummary: "Você será conectado ao {{method}} para concluir seu pagamento. Não se preocupe, todo o processo é 100% seguro!",
      },
    },
    buttonDeleteAccount: "Excluir conta",
    modalTitle: "Confirmação de exclusão",
    modalMessage:
      "Tem certeza de que deseja excluir sua conta? Esta ação " +
      '<span class="text-danger fw-bold">NÃO</span> pode ser desfeita.',
    cancelButtonText: "Cancelar",
    deleteButtonText: "Quero excluir",
  },
  "en-US": {
    title: "Empower Your Journey",
    menu: {
      general: "General",
      security: "Security",
      credits: "Credits",
      affiliation: "Affiliation",
      support: "Support",
    },
    buttons: {
      logout: "Logout",
      deleteAllChats: "Delete all chats",
      deleteAccount: "Delete Account",
      pix: "Copy Pix code",
    },
    general: {
      personalInfo: {
        title: "Personal Info",
        fullName: "Full Name",
        email: "Email",
        user: "User",
      },
      preferences: {
        title: "Preferences",
        language: "Language",
      },
      system: {
        title: "System",
        activeSession: "Active Session",
        chatHistory: "Chat History",
        deleteAccount: "Delete Account",
      },
    },
    security: {
      title: "Change password",
      newPassword: "New password",
      confirmPassword: "Confirm password",
      buttonSave: "Save",
    },
    credits: {
      title: "Credits",
      invoice: "Invoice History",
      buyCredits: "Buy Credits",
      pix: "Add credits quickly and easily by scanning the QR CODE below"
    },
    affiliation: {
      affiliateProgram: {
        title: "Affiliate Program",
        subtitle: "Introducing our affiliate program",
        description:
          "As an affiliate, you will have the opportunity to share our AI with your network. And the best part? You will receive a 10% commission for every subscription made through your exclusive link.",
        question: "How it works?",
        stepOne: "1. Copy your affiliate link",
        stepTwo:
          "2. Share it in your social media, blog, websites or any channel",
        stepThree:
          "3. The more subscriptions are made through, higher earning you’ll have!",
        button: "Get my affiliate link",
        terms:
          "By using the affiliate link, you agree to the terms and conditions of our affiliate program. For more information, please refer to our Privacy Policy.",
      },
    },
    taximeter: {
      title: "Taximeter",
      subtitle: "Introducing our affiliate program",
      description:
        "As an affiliate, you will have the opportunity to share our AI with your network. And the best part? You will receive a 10% commission for every subscription made through your exclusive link.",
    },
    support: {
      title: "Get Help",
      community: "Community",
      support: "Support",
    },
    modals: {
      credits: {
        label: "Buy credits",
        method: {
          title: "Select payment method",
          card: "Card",
          aiz: "Você precisará de aproximadamente",
          aizContinue: "tokens to complete this transaction.",
        },
        value: {
          title: "Value credits",
        },
        button: {
          pay: "Pay with",
          cancel: "Cancel",
        },
        textOrderSummary: "You’ll be connected with {{method}} to complete your payment. Don’t worry, the whole process is 100% safe and secure!",
      },
    },
    buttonDeleteAccount: "Delete account",
    modalTitle: "Deletion Confirmation",
    modalMessage:
      "Are you sure you want to delete your account? This action " +
      '<span class="text-danger fw-bold">CANNOT</span> be undone.',
    cancelButtonText: "Cancel",
    deleteButtonText: "I want to delete",
  },
  "es-ES": {
    title: "Potencia Tu Viaje",
    menu: {
      general: "General",
      security: "Seguridad",
      credits: "Créditos",
      affiliation: "Afiliación",
      support: "Soporte",
    },
    buttons: {
      logout: "Desconectar",
      deleteAllChats: "Eliminar todos los chats",
      deleteAccount: "Eliminar la Cuenta",
      pix: "Copiar código Pix",
    },
    general: {
      personalInfo: {
        title: "Información personal",
        fullName: "Nombre Completo",
        email: "Email",
        user: "Usuario",
      },
      preferences: {
        title: "Preferencias",
        language: "Idioma",
      },
      system: {
        title: "Sistema",
        activeSession: "Sesión Activa",
        chatHistory: "Historial de Conversaciones",
        deleteAccount: "Eliminar la Cuenta",
      },
    },
    security: {
      title: "Cambiar contraseña",
      newPassword: "Nueva contraseña",
      confirmPassword: "Confirmar contraseña",
      buttonSave: "Guardar",
    },
    credits: {
      title: "Créditos",
      invoice: "Historial de facturas",
      buyCredits: "Comprar créditos",
      pix: "Agregue créditos de manera rápida y sencilla escaneando el CÓDIGO QR a continuación"
    },
    affiliation: {
      affiliateProgram: {
        title: "Programa de afiliación",
        subtitle: "Te presentamos nuestro programa de afiliación",
        description:
          "Como afiliado, tendrás la oportunidad de compartir nuestra IA con tu red. ¿Y la mejor parte? Recibirás una comisión del 10% por cada suscripción realizada a través de tu enlace exclusivo.",
        question: "¿Cómo funciona?",
        stepOne: "1. Copia tu enlace de afiliado",
        stepTwo:
          "2. Compártelo en tus redes sociales, blog, sitios web o cualquier canal",
        stepThree:
          "3. ¡Cuanto más suscripciones se hagan a través de tu enlace, mayor será tu ganancia!",
        button: "Obtener mi enlace de afiliado",
        terms:
          "Al utilizar el enlace de afiliado, aceptas los términos y condiciones de nuestro programa de afiliación. Para obtener más información, consulta nuestra Política de Privacidad.",
      },
    },
    taximeter: {
      title: "Taxímetro",
      subtitle: "Te presentamos nuestro programa de afiliación",
      description:
        "Como afiliado, tendrás la oportunidad de compartir nuestra IA con tu red. ¿Y la mejor parte? Recibirás una comisión del 10% por cada suscripción realizada a través de tu enlace exclusivo.",
    },
    support: {
      title: "Obtener ayuda",
      community: "Comunidad",
      support: "Soporte",
    },
    modals: {
      credits: {
        label: "Comprar créditos",
        method: {
          title: "Seleccionar método de pago",
          card: "Tarjeta",
          aiz: "Debes tener aproximadamente",
          aizContinue: "tokens para completar esta transacción.",
        },
        value: {
          title: "Créditos de valor",
        },
        button: {
          pay: "Pagar con",
          cancel: "Cancelar",
        },
        textOrderSummary: "Se te conectará con {{method}} para completar tu pago. No te preocupes, todo el proceso es 100% seguro y confiable!",
      },
    },
    buttonDeleteAccount: "Eliminar cuenta",
    modalTitle: "Confirmación de eliminación",
    modalMessage:
      "¿Estás seguro de que quieres eliminar tu cuenta? Esta acción " +
      '<span class="text-danger fw-bold">NO SE</span> puede deshacer.',
    cancelButtonText: "Cancelar",
    deleteButtonText: "Quiero eliminar",
  },
};
