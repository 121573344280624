import { useState, useEffect } from "react";
import { useUser } from "@supabase/auth-helpers-react";
import { showErrorNotification } from "../../services/notifications";
import {
  likeCommunityAssistant,
  unlikeCommunityAssistant,
} from "../../api/assistants";
const LikeAssistants = ({ assistant, likes }) => {
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [isUserLiked, setIsUserLiked] = useState(false);
  const user = useUser();

  const handleLikeContact = async () => {
    if (isLikeLoading) return;
    try {
      setIsLikeLoading(true);
      await likeCommunityAssistant(assistant?._id || assistant?.objectID);
      setIsUserLiked(true);
    } catch (error) {
      showErrorNotification(error);
    } finally {
      setIsLikeLoading(false);
    }
  };

  const handleUnlikeContact = async () => {
    if (isLikeLoading) return;
    try {
      setIsLikeLoading(true);
      await unlikeCommunityAssistant(assistant?._id || assistant?.objectID);
      setIsUserLiked(false);
    } catch (error) {
      showErrorNotification(error);
    } finally {
      setIsLikeLoading(false);
    }
  };

  useEffect(() => {
    setIsUserLiked(
      assistant?.likedByMe || assistant?.likedby?.includes(user?.id)
    );
  }, [assistant?.likedByMe, assistant?.likedby, user?.id]);

  const onIconClick = event => {
    event.stopPropagation();
    if (isUserLiked) {
      handleUnlikeContact();
    } else {
      handleLikeContact();
    }
  };

  return (
    <div className="d-flex align-items-center gap-1">
      {isUserLiked ? (
        <button
          // onClick={onIconClick}
          className="btn heartBeat m-0 p-0 d-flex align-items-center justify-content-center"
          style={{ width: 16, height: 16 }}
        >
          <i className="bx bxs-heart" style={{ color: "red" }} />
        </button>
      ) : (
        <button
          // onClick={onIconClick}
          className="btn btn-sm m-0 p-0 d-flex align-items-center justify-content-center"
          style={{ width: 16, height: 16 }}
        >
          <i className="bx bx-heart" />
        </button>
      )}
      <span>{likes}</span>
    </div>
  );
};

export default LikeAssistants;