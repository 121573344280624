import { useSelector } from "react-redux";
import { IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import useTranslation from "../../../hooks/useTranslation";
import { ModalCreateAssistantsI18n } from "./ModalCreateAssistants.i18n";


const ReviewPublishAssistants = () => {

  const { t } = useTranslation(ModalCreateAssistantsI18n);

  const assistantData = useSelector((state) => state.assistantReducer.assistantData);

  return (
    <div className="review-publish-assistants" style={{ paddingRight: "10px" }}>
      <div className="create-assistants-general-info">
        <div className="d-flex w-100 justify-content-between align-items-center">
          <span className="span">{t("tabs.review.inputs.name.label")}</span>
          <div className="div-input-general-info">
            <input type="text" value={assistantData.name} readOnly disabled />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <span className="span">{t("tabs.review.inputs.about.label")}</span>
          <div className="div-input-general-info">
            <input
              value={assistantData.about}
              readOnly
              disabled
            />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <span className="span">{t("tabs.review.inputs.role.label")}</span>
          <div className="div-input-general-info">
            <input type="text" value={assistantData.role} readOnly disabled />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <span className="span">{t("tabs.review.inputs.category.label")}</span>
          <div className="div-input-general-info">
            <input type="text" value={assistantData.category} readOnly disabled />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <span className="span">{t("tabs.review.inputs.active.label")}</span>
          <div className="div-input-general-info">
            <input type="text"
              value={assistantData.enabled === false ? "Não" : assistantData.enabled === true ? "Sim" : ""}
              readOnly
              disabled />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <span className="span">{t("tabs.review.inputs.control.label")}</span>
          <div className="div-input-general-info">
            <input type="text"
              value={assistantData.private === false ?
                "Público (Qualquer usuário poderá visualizá-lo)"
                : assistantData.private === true ? "Privado (Somente você poderá visualizá-lo)"
                  : ''} readOnly disabled />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between align-items-start">
          <span className="span">{t("tabs.review.inputs.avatar.label")}</span>
          <div
            className="select-avatar w-100 d-flex flex-column justify-content-start gap-2"
          >
            <div
              style={{ width: 110, height: 40 }}
              className="d-flex justify-content-center align-items-center div-input-upload-avatar"
            >
              <div className="d-flex justify-content-between align-items-center w-100 px-2">
                <img
                  src={assistantData.avatar}
                  alt=""
                  width={24}
                  height={24}
                  style={{ borderRadius: "50%" }}
                />
                <IconCheck size={12} stroke={3} />
              </div>
            </div>
            {/* <FileUploader
            classes="upload-img"
            name="Image"
            types={typesSupported}
          /> */}
            {/* <div
              style={{ width: 110 }}
              className="d-flex justify-content-center align-items-center div-input-upload-avatar"
            >
              <input
                type="text"
                placeholder="Upload"
                value={assistantData?.avatar}
                readOnly
              />
              <IconPlus />
            </div>
            <div className="d-flex gap-2 align-items-center">
              <Checkbox style={{ height: 15 }} />
              <span className="span">Generate with AI</span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="create-assistants-data">
        <div className="d-flex w-100 justify-content-between align-items-start">
          <span className="span">{t("tabs.review.inputs.prompt.label")}</span>
          <div className="textarea-data">
            <textarea
              value={assistantData.prompt}
              readOnly
              disabled
            />
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between align-items-center">
          <span className="span">{t("tabs.review.inputs.language.label")}</span>
          <div className="div-input-general-info">
            <input type="text" value={assistantData.language} readOnly disabled />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewPublishAssistants;
