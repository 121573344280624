// hooks/useSendMessage.ts
import { useChatStore } from "../state/chat";
import { showWarningNotification } from "../services/notifications";
import { nanoid } from "nanoid";
import { getBearerToken } from "../services/localStorageHelpers";
import {
  ChatRepository,
  makeChatId,
} from "../services/indexedDB/chat-repository";
import { useGetConfigsMainChat } from "./smallHooks";
import { DocumentRepository } from "../services/indexedDB/document-repository";
import { createTextEmbeddings } from "../api/embeddings";
import { getCurrentUserLanguage, getUser } from "../services/userHelper";

import { cryptoService } from "../services/crypto";
import { useChatWebSocketContext } from "../contexts/ChatWebscoketContext";
import useUserCredits from "./useUserCredits";

const useSendMessage = () => {
  const configsMainChat = useGetConfigsMainChat();

  const user = getUser();

  const storedConfigs = JSON.parse(
    localStorage.getItem("configsMainChat") || "{}"
  );

  const selectedModel = storedConfigs?.machine?.name || storedConfigs?.machine;

  const { sendWebsocketMessage, cancelMessage } = useChatWebSocketContext();
  const { setNewMessage, isReceivingMessage, disableCancelMessage } =
    useChatStore();
  const { hasSufficientCredits, hasSubscriptionActive } = useUserCredits();

  const isActiveSubscription = hasSubscriptionActive();

  const chatRepository = new ChatRepository();
  const documentRepository = new DocumentRepository();

  const userLanguage = getCurrentUserLanguage();

  const sendMessage = async (question, isAudioMessage = false) => {
    const isFreeModel = selectedModel?.toLowerCase().includes("free");

    if (!isFreeModel) {
      if (!hasSufficientCredits() && !isActiveSubscription) {
        return;
      }
    }

    const messageId = nanoid();
    const messageBody = {
      question: question,
      assistantId: configsMainChat.assistant?._id ?? null,
      channelId: configsMainChat.chatData?._id,
      messageId,
      isAudioMessage,
      enableWebSearch: configsMainChat.enableWebSearch,
      userLanguage,
      profileImage: configsMainChat?.assistant?.profileImage,
      model: configsMainChat.machine,
    };

    const message = {
      body: messageBody,
      jwt: getBearerToken(),
    };

    const messageData = {
      mId: messageId,
      chat_request: question,
      profileImage: configsMainChat?.assistant?.profileImage,
      chat_response: {
        content: "",
      },
    };

    setNewMessage(messageData);

    let typeChatPrefix = configsMainChat.typeChat?.prefix || "";

    const hasDocumentOnContext =
      await documentRepository.hasActiveDocumentByChannelAndUser(
        configsMainChat.chatData?._id,
        user.id
      );

    if (hasDocumentOnContext) {
      typeChatPrefix = "/attachment";
    }

    if (typeChatPrefix === "/attachment") {
      const { documents } = await createTextEmbeddings({
        channelId: configsMainChat.chatData._id,
        text: question,
      });

      const postgresIds = documents.map(document => document.id);

      const compressedContent =
        await documentRepository.getCompressedDocumentsByPostgresIds(
          postgresIds
        );

      message.body.compressedDocuments = compressedContent ?? null;
    }

    message.body.typeChat = {
      prefix: typeChatPrefix,
    };

    try {
      const messages = await chatRepository.getMessagesByChatId(
        makeChatId(
          configsMainChat?.chatData._id,
          configsMainChat.chatData?.userId
        )
      );

      const lastMessages = messages?.reverse().slice(0, 7).reverse();

      const messagePairs =
        lastMessages?.flatMap(msg => [
          {
            role: "user",
            content: msg.chat_request,
          },
          {
            role: "assistant",
            content: msg.chat_response?.content,
          },
        ]) || [];

      const data = {
        route: "createChatMessage",
        jwt: message.jwt,
        body: {
          ...message.body,
          lastMessages: messagePairs,
        },
      };

      const messageString = JSON.stringify(data);

      const encryptedMessage = cryptoService.encrypt(messageString);

      sendWebsocketMessage(encryptedMessage);
    } catch (error) {
      showWarningNotification(error);
    }
  };

  const sendMessageAgain = async (existingMessage, isAudioMessage = false) => {
    const isFreeModel = selectedModel?.toLowerCase().includes("free");

    if (!isFreeModel) {
      if (!hasSufficientCredits()) {
        return;
      }
    }

    const question = existingMessage?.chat_request;

    const messageId = existingMessage?._id || existingMessage?.mId || nanoid();
    const messageBody = {
      question,
      assistantId: configsMainChat.assistant?._id ?? null,
      channelId: configsMainChat.chatData?._id,
      messageId,
      isAudioMessage,
      enableWebSearch: configsMainChat.enableWebSearch,
      userLanguage,
      profileImage: configsMainChat?.assistant?.profileImage,
      model: configsMainChat.machine,
    };

    const message = {
      body: messageBody,
      jwt: getBearerToken(),
    };

    const messageData = {
      mId: messageId,
      chat_request: question,
      profileImage: configsMainChat?.assistant?.profileImage,
      chat_response: {
        content: "",
      },
    };

    setNewMessage(messageData);

    let typeChatPrefix = configsMainChat.typeChat?.prefix || "";

    const hasDocumentOnContext =
      await documentRepository.hasActiveDocumentByChannelAndUser(
        configsMainChat.chatData?._id,
        user.id
      );

    if (hasDocumentOnContext) {
      typeChatPrefix = "/attachment";
    }

    if (typeChatPrefix === "/attachment") {
      const { documents } = await createTextEmbeddings({
        channelId: configsMainChat.chatData._id,
        text: question,
      });

      const postgresIds = documents.map(document => document.id);

      const compressedContent =
        await documentRepository.getCompressedDocumentsByPostgresIds(
          postgresIds
        );

      message.body.compressedDocuments = compressedContent ?? null;
    }

    message.body.typeChat = {
      prefix: typeChatPrefix,
    };

    try {
      const messages = await chatRepository.getMessagesByChatId(
        makeChatId(
          configsMainChat?.chatData._id,
          configsMainChat.chatData?.userId
        )
      );

      const lastMessages = messages?.reverse().slice(0, 7).reverse();

      const messagePairs =
        lastMessages?.flatMap(msg => [
          {
            role: "user",
            content: msg.chat_request,
          },
          {
            role: "assistant",
            content: msg.chat_response?.content,
          },
        ]) || [];

      const data = {
        route: "createChatMessage",
        jwt: message.jwt,
        body: {
          ...message.body,
          lastMessages: messagePairs,
        },
      };

      const messageString = JSON.stringify(data);

      const encryptedMessage = cryptoService.encrypt(messageString);

      sendWebsocketMessage(encryptedMessage);
    } catch (error) {
      showWarningNotification(error);
    }
  };

  return {
    isReceivingMessage,
    disableCancelMessage,
    cancelMessage,
    sendMessage,
    sendMessageAgain,
  };
};

export default useSendMessage;
