import Checkbox from "antd/es/checkbox/Checkbox";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import { useSelector } from "react-redux";

const CardPayment = ({
  value,
  valueAmount,
  setValueAmount,
  handleInputValue,
  method,
  titleMethod,
  methodIcon,
  selectedPayment,
  handleChange,
  handleBlur,
  checked,
}) => {
const state = useSelector(state => state)

  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG
  return (
    <div
      className="w-100 d-flex align-items-center"
      style={{
        maxWidth: 800,
        height: isMobile ? 54 : 64,
        borderRadius: 12,
        border: "1px solid #D0D5DD",
        padding: "0 20px",
      }}
    >
      {value && (
        <div className="w-100 d-flex align-items-center gap-2">
          <span>$</span>
          <input
            value={valueAmount}
            style={{ border: "none", fontSize: 13 }}
            onChange={handleInputValue}
            onFocus={() => setValueAmount("")}
            onBlur={handleBlur}
            min={10}
          />
        </div>
      )}
      {method && (
        <div className="w-100 d-flex align-items-center justify-content-between gap-2">
          <div className="d-flex align-items-center gap-2">
            <Checkbox
              checked={checked}
              value={selectedPayment}
              onChange={handleChange}
            />
            <span>{titleMethod}</span>
          </div>
          <div className="d-flex align-items-center gap-2">{methodIcon}</div>
        </div>
      )}
    </div>
  );
};

export default CardPayment;
