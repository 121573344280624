import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetConfigsMainChat } from "../../../../hooks/smallHooks";
import Tooltip from "../../../Add-ons/Tooltip";
import { setConfigsMainChat } from "../../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../../services/chat/defaultConfigsMainChat";
import { useChatStore } from "../../../../state/chat";
import useTranslation from "../../../../hooks/useTranslation";
import { PageChatI18n } from "../../../Add-ons/PageChat.i18n";

const ChangeTypeChat = () => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const { isReceivingMessage } = useChatStore();

  const { t } = useTranslation(PageChatI18n)

  const configsMainChat = useGetConfigsMainChat();

  useEffect(() => {
    if (configsMainChat?.typeChat?.title !== "message") {
      const defaultMessageType =
        state?.configsGeneralReducer?.listTypeChats?.find(
          item => item.title === "message"
        );
      if (defaultMessageType) {
        dispatch(
          setConfigsMainChat(KEYS_MAIN_CHAT.typeChat, defaultMessageType)
        );
      }
    }
  }, []);

  return (
    <div className="buttons-change-type-chat">
      {state?.configsGeneralReducer?.listTypeChats?.map(item => (
        <Tooltip key={item.title} text={t(`tooltip.${item.description}`)}>
          <button
            type="button"
            className={`${
              configsMainChat?.typeChat?.title === item.title ? "active" : ""
            }`}
            disabled={isReceivingMessage}
            onClick={() => {
              if (item.title === "Web Searching") {
                dispatch(
                  setConfigsMainChat(KEYS_MAIN_CHAT.enableWebSearch, true)
                );
              } else {
                dispatch(
                  setConfigsMainChat(KEYS_MAIN_CHAT.enableWebSearch, false)
                );
              }
              dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.typeChat, item));
            }}
          >
            <div className="d-flex gap-1">
              {item?.icon}
              {configsMainChat?.typeChat?.title === item.title && (
                <span className="text-capitalize">{item?.title}</span>
              )}
            </div>
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

export default ChangeTypeChat;
