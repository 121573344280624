import { useState } from "react";
import ButtonType1 from "../../Buttons/ButtonType1";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from "../../../services/notifications";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import useTranslation from "../../../hooks/useTranslation";
import { PageSettingsI18n } from "./PageSettings.i18n";

const SecuritySettings = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const supabaseClient = useSupabaseClient();

  const { t } = useTranslation(PageSettingsI18n);

  const widthButtonEdit =
    state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.XL
      ? 300
      : state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.LG
      ? 250
      : state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.SM
      ? 300
      : 220;

  const user = useUser();

  const widthLabelSpan =
    state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.LG ? 150 : "auto";

  // const verifyPassword = async () => {
  //   try {
  //     const { error } = await supabaseClient.auth.({
  //       email: user?.email,
  //       password: currentPassword,
  //     });
  //     console.log("user", user);
  //     if (error) {
  //       throw new Error("Senha incorreta");
  //     }
  //     console.log("Senha correta!");
  //   } catch (error) {
  //     console.log("Senha incorreta!");
  //   }
  // };

  async function handleUpdatePassword() {
    try {
      if (!newPassword) {
        return;
      }
      if (newPassword?.length < 6) {
        return showWarningNotification(
          "Password must be at least 6 characters"
        );
      }

      setLoading(true);
      const { error } = await supabaseClient.auth.updateUser({
        password: newPassword,
      });

      if (error?.message) {
        return showErrorNotification(error?.message);
      }

      showSuccessNotification("Password updated successfully");
    } catch (erro) {
      showErrorNotification(erro.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  }

  const handleSave = () => {
    handleUpdatePassword();
  };

  return (
    <div className="d-flex flex-column gap-3">
      <h4>{t("security.title")}</h4>
      <div className="d-flex flex-column gap-3">
        {/* <div className="d-flex justify-content-between align-items-center">
          <span style={{ width: widthLabelSpan }}>Current password</span>
          <input
            type="password"
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)}
            style={{ width: widthButtonEdit, padding: "0 16px" }}
          />
        </div> */}
        <div className="d-flex justify-content-between align-items-center">
          <span style={{ width: widthLabelSpan }}>{t("security.newPassword")}</span>
          <input
            type="password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            style={{ maxWidth: widthButtonEdit, padding: "0 16px" }}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span style={{ width: widthLabelSpan }}>{t("security.confirmPassword")}</span>
          <input
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            style={{ width: widthButtonEdit, padding: "0 16px" }}
          />
        </div>
        <div className="w-1000 d-flex justify-content-end">
          <ButtonType1
            variation="primary"
            text={t("security.buttonSave")}
            isLoading={loading}
            props={{
              onClick: handleSave,
              style: {
                padding: "10px",
                width: 120,
                borderRadius: 10,
                marginLeft: 8,
              },
              disabled:
                // !currentPassword ||
                !newPassword ||
                !confirmPassword ||
                newPassword !== confirmPassword,
            }}
          ></ButtonType1>
        </div>
      </div>
    </div>
  );
};

export default SecuritySettings;
