import { Select } from "antd";
import IconArrowDown from "../../../assets/images/icons-new-layout/arrow-down.svg";
import { IconPaperclip } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { updateAssistantData } from "../../../redux/createAssistants/action";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "../../Add-ons/Tooltip";
import useTranslation from "../../../hooks/useTranslation";
import { ModalCreateAssistantsI18n } from "./ModalCreateAssistants.i18n";

const CreateAssistantData = () => {
  const dispatch = useDispatch();
  const assistantData = useSelector(
    state => state.assistantReducer.assistantData
  );

  const { t } = useTranslation(ModalCreateAssistantsI18n);

  const [prompt, setPrompt] = useState(assistantData?.prompt || "");
  const [defaultGreetings, setDefaultGreetings] = useState(
    assistantData?.defaultGreetings || ""
  );
  const [knowledge, setKnowledge] = useState(assistantData?.knowledge || "");
  const [language, setLanguage] = useState(assistantData?.language || "");

  useEffect(() => {
    setPrompt(assistantData?.prompt || "");
    setDefaultGreetings(assistantData?.defaultGreetings || "");
    setLanguage(assistantData?.language || "");
  }, [assistantData]);

  const handlePromptChange = e => {
    const value = e.target.value;
    setPrompt(value);
    dispatch(updateAssistantData({ prompt: value }));
  };

  // const handleGreetingsChange = e => {
  //   const value = e.target.value;
  //   setDefaultGreetings(value);
  //   dispatch(updateAssistantData({ defaultGreetings: value }));
  // };

  const handleLanguageChange = value => {
    setLanguage(value);
    dispatch(updateAssistantData({ language: value }));
  };

  // const handleKnowledgeChange = e => {
  //   const value = e.target.value;
  //   setKnowledge(value);
  //   dispatch(updateAssistantData({ knowledge: value }));
  // };

  // const textTooltip = "This database of texts, books, and links helps train the chatbot, improving its responses by learning from diverse information sources."


  return (
    <div className="create-assistants-data">
      <div className="d-flex w-100 justify-content-between align-items-start">
        <span className="span">{t("tabs.data.inputs.prompt.label")}</span>
        <div className="textarea-data">
          <textarea
            value={prompt}
            onChange={handlePromptChange}
            placeholder={t("tabs.data.inputs.prompt.placeholder")}
          />
        </div>
      </div>
      {/* <div className="d-flex w-100 justify-content-between align-items-start">
        <span className="span">Default Greetings</span>
        <div className="textarea-data">
          <textarea
            value={defaultGreetings}
            onChange={handleGreetingsChange}
            placeholder="Enter the initial greeting of the assistant"
          />
        </div>
      </div> */}
      <div className="d-flex w-100 justify-content-between align-items-center">
        <span className="span">{t("tabs.data.inputs.language.label")}</span>
        <Select
        className="select-antd-create-assistant"
          suffixIcon={
            <img src={IconArrowDown} height={10} width={10} alt="Arrow down" />
          }
          
          size="large"
          showSearch
          filterOption={false}
          value={language === '' ? "Select language" : language}
          onChange={handleLanguageChange}
          placeholder="Select language"
        >
          <Select.Option value="English">English</Select.Option>
          <Select.Option value="Spanish">Spanish</Select.Option>
          <Select.Option value="Português">Português</Select.Option>
        </Select>
      </div>
      {/* <div className="d-flex w-100 justify-content-between align-items-start">
        <div className="d-flex gap-2 align-items-center">
          <span className="span">Knowledge</span>
          <Tooltip
            text={textTooltip}
            key={""}
            children={""}
          >
            <button
              className="d-flex justify-content-center align-items-center"
              style={{
                border: "1px solid #D0D5DD",
                borderRadius: "50%",
                width: 20,
                height: 20,
              }}
            >
              !
            </button>
          </Tooltip>
        </div>{" "}
        <div className="textarea-data position-relative">
          <textarea
            value={knowledge}
            onChange={handleKnowledgeChange}
            placeholder="Enter additional information"
          />
          <button style={{ position: "absolute", top: 16, right: 16 }}>
            <IconPaperclip stroke={1.5} style={{ width: 16, height: 16 }} />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default CreateAssistantData;
