import { useDispatch, useSelector } from "react-redux";
import { setIsLogged, setOpenLogin } from "../../redux/general/action";
import { CSSTransition } from "react-transition-group";
import SwitchWebMode from "./components/SwitchWebMode";
import Web2Login from "./components/Web2Login";
import Web3Login from "./components/Web3Login";
import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { getAndUpdateUser, getUser } from "../../services/userHelper";
import { useEffect, useState } from "react";
import { showErrorNotification } from "../../services/notifications";
import { toast } from "sonner";
import { requestChallenge, verifyChallenge } from "../../api";
import { ethers } from "ethers";
import COLORS from "../../utils/constants/colors";
import { IconChevronRightPipe } from "@tabler/icons-react";
import useTranslation from "../../hooks/useTranslation";
import { GroupLoginI18n } from "./GroupLogin.i18n";

const STORAGE_KEYS = {
  USER_WALLET_SIGN: "signature",
  GOOGLE_LOGIN_REDIRECT: "google_login_redirect",
};

function GroupLogin({ isShow }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const supabaseClient = useSupabaseClient();

  const { t } = useTranslation(GroupLoginI18n);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isResetingPassword, setIsResetingPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const user = getUser();
  const session = useSession();

  const googleRedirect = localStorage.getItem(
    STORAGE_KEYS.GOOGLE_LOGIN_REDIRECT
  );

  async function fetchAndSaveUserData() {
    try {
      setIsLoadingGoogle(true);
      await getAndUpdateUser();

      dispatch(setIsLogged(true));
      dispatch(setOpenLogin(false));
      setIsLoadingGoogle(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSocialLogin() {
    try {
      setIsLoadingGoogle(true);

      localStorage.setItem(STORAGE_KEYS.GOOGLE_LOGIN_REDIRECT, "true");

      const { error } = await supabaseClient.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${process.env.REACT_APP_PUBLIC_SITE_URL}`,
        },
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      console.error(error);
      showErrorNotification(
        error?.message ??
          "Failed to login with Google, please try again or contact support"
      );
    } finally {
      setIsLoadingGoogle(false);
    }
  }

  async function handleLoginWithCredentials() {
    try {
      setIsLoading(true);
      const { error } = await supabaseClient.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (error) {
        toast.error(error.message);
        return;
      }

      await getAndUpdateUser();

      dispatch(setIsLogged(true));
      dispatch(setOpenLogin(false));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRegister() {
    try {
      setIsLoading(true);

      const { error } = await supabaseClient.auth.signUp({
        email: email,
        password: password,
        options: {
          emailRedirectTo: process.env.REACT_APP_PUBLIC_SITE_URL,
        },
      });

      if (error) {
        return showErrorNotification(error.message);
      }

      const emailDomain = email.split("@")[1].toLowerCase();

      const emailProviders = {
        "gmail.com": "https://mail.google.com",
        "outlook.com": "https://outlook.live.com",
        "hotmail.com": "https://outlook.live.com",
        "yahoo.com": "https://mail.yahoo.com",
      };

      const redirectToEmailProvider = () => {
        const url = emailProviders[emailDomain];
        if (url) {
          window.open(url, "_blank");
        }
      };

      toast(
        "Account created! Please confirm your email and check your spam folder if you don't see it.",
        {
          action: {
            label: "OK",
            onClick: () => {
              redirectToEmailProvider();
            },
          },
          duration: Infinity,
          className: "toast-no-creditis",
        }
      );
    } catch (err) {
      showErrorNotification(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleLoginWithWeb3() {
    const signatureStorage = localStorage.getItem(
      STORAGE_KEYS.USER_WALLET_SIGN
    );

    if (signatureStorage) {
      return signatureStorage;
    }

    try {
      setIsLoading(true);
      const provider = new ethers.BrowserProvider(window.ethereum);

      await provider.send("eth_requestAccounts", []);

      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      const addressId = accounts[0];

      const challengeResponse = await requestChallenge(addressId);
      const challenge = challengeResponse;

      if (!challenge.success) {
        return showErrorNotification(
          challenge.data ?? "Failed to login with Metamask"
        );
      }

      const ethereum = await window?.ethereum;
      const signer = await new ethers.BrowserProvider(ethereum).getSigner();

      const signature = await signer.signMessage(challenge.data);

      const verifyResponse = await verifyChallenge({
        message: challenge.data,
        signature: signature,
      });

      const authData = verifyResponse;

      await supabaseClient.auth.signInWithPassword({
        email: authData.user.email,
        password: addressId.toLowerCase(),
      });

      await getAndUpdateUser();

      dispatch(setIsLogged(true));
      dispatch(setOpenLogin(false));
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        return;
      }

      if (error.code && error.shortMessage) {
        return showErrorNotification(error.shortMessage);
      }

      showErrorNotification(
        error?.message ||
          "Failed to login with Metamask, please try again or contact support"
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!user && session && !googleRedirect && !isLoading) {
      fetchAndSaveUserData();
    }
  }, [session]);

  useEffect(() => {
    if (googleRedirect) {
      dispatch(setOpenLogin(true));
      localStorage.removeItem(STORAGE_KEYS.GOOGLE_LOGIN_REDIRECT);
    }
  }, []);

  return (
    <CSSTransition
      in={isShow}
      classNames="slide-in-right-300"
      timeout={300}
      unmountOnExit
    >
      <section className="group-main-login">
        <button
          onClick={() => dispatch(setOpenLogin(false))}
          className="align-self-end mb-4 btn-close-login"
          style={{
            height: 32,
            width: 32,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 8,
            background: COLORS.gray100,
          }}
        >
          <IconChevronRightPipe
            style={{
              width: 16,
              height: 16,
              transition: "all .2s",
              transform: `rotate(${
                state?.configsGeneralReducer?.openLeftBar ? 0 : 180
              }deg)`,
            }}
          />
        </button>
        <div className="header ">
          <div className="title mb-5">
            {state?.configsGeneralReducer?.webModeLogin === "Web 2" ? (
              <>
                {isResetingPassword ? (
                  <h3>{t("recoverPassword")}</h3>
                ) : (
                  <h3>{isRegistering ? t("createAccount") : t("signIn")}</h3>
                )}
              </>
            ) : (
              <h3>{t("wallet")}</h3>
            )}
            <SwitchWebMode />
          </div>
          {state?.configsGeneralReducer?.webModeLogin === "Web 2" ? (
            <>
              {!isResetingPassword ? (
                <p>
                  {isRegistering ? t("okAccount") : t("newAizzy")}
                  <button onClick={() => setIsRegistering(!isRegistering)}>
                    {isRegistering ? t("signIn") : t("getStarted")}
                  </button>
                </p>
              ) : (
                <button>
                  <span onClick={() => setIsResetingPassword(false)}>
                    {t("backToLogin")}
                  </span>
                </button>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        {state?.configsGeneralReducer?.webModeLogin === "Web 2" ? (
          <Web2Login
            isRegistering={isRegistering}
            isResetingPassword={isResetingPassword}
            setIsResetingPassword={setIsResetingPassword}
            handleLogin={
              isRegistering ? handleRegister : handleLoginWithCredentials
            }
            email={email}
            handleSocialLogin={handleSocialLogin}
            isLoading={isLoading}
            isLoadingGoogle={isLoadingGoogle}
            onChangeEmail={e => setEmail(e.target.value)}
            onChangePassword={e => setPassword(e.target.value)}
          />
        ) : (
          <Web3Login handleLogin={handleLoginWithWeb3} isLoading={isLoading} />
        )}
      </section>
    </CSSTransition>
  );
}

export default GroupLogin;
