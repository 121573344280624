import { create } from "lodash";

export const PageAssistantsI18n = {
  "pt-BR": {
    sigIn: "Entrar",
    button: "Ver mais",
    create: "Criar",
    publish: "Publicar Assistente",
    title: "Potencialize Sua Jornada",
    paragraph: "Desbloqueie o potencial do seu fluxo de trabalho com nossos assistentes intuitivos",
    tabs: {
      top: "Melhores escolhas",
      aizzy: "App Aizzy",
      community: {
        title: "Comunidade",
        previous: "Página anterior",
        next: "Próxima página",
        totalAssistants: "Total de assistentes",
      },
      my: {
        title: "Meus Assistentes",
        noAssistants: "Você ainda não tem nenhum assistente criado...",
        create: "Crie agora seu próprio assistente!",
      },
    },
    subTitles: {
      trending: "Em alta",
      byAizzyApp: "Por Aizzy App",
      paragraphTrending: "Principais assistentes usados",
      paragraphAizzyApp: "Explore os assistentes criados pela equipe AizzyApp",
    },
  },
  "en-US": {
    sigIn: "Sign In",
    button: "See More",
    create : "Create",
    publish: "Publish Assistant",
    title: "Empower Your Journey",
    paragraph: "Unleash the potential of your workflow with our intuitive assistants",
    tabs: {
      top: "Top picks",
      aizzy: "Aizzy App",
      community: {
        title: "Community",
        previous: "Previous Page",
        next: "Next Page",
        totalAssistants: "Total Assistants",
      },
      my: {
        title: "My Assistants",
        noAssistants: "You don't have any assistant created yet...",
        create: "Create now your personal assistant!",
      }
    },
    subTitles: {
      trending: "Trending",
      byAizzyApp: "By Aizzy App",
      paragraphTrending: "Top used assistants",
      paragraphAizzyApp: "Explore the assistants created by AizzyApp team",
    },
  },
  "es-ES": {
    sigIn: "Iniciar sesión",
    button: "Ver más",
    create : "Crear",
    publish: "Publicar Asistente",
    title: "Potencia Tu Viaje",
    paragraph: "Desbloquea el potencial de tu flujo de trabajo con nuestros asistentes intuitivos",
    tabs: {
      top: "Mejores elecciones",
      aizzy: "App Aizzy",
      community: {
        title: "Comunidad",
        previous: "Página anterior",
        next: "Próxima página",
        totalAssistants: "Total de asistentes",
      },
      my: {
        title: "Mis Asistentes",
        noAssistants: "No tienes ninguño asistente creado todavía...",
        create: "Cree ahora su asistente personal!",
      },
    },
    subTitles: {
      trending: "Tendencias",
      byAizzyApp: "Por Aizzy App",
      paragraphTrending: "Principales asistentes utilizados",
      paragraphAizzyApp: "Explora los asistentes creados por el equipo de AizzyApp",
    },
  },
};