/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfigsMainChat,
  setListAssistants,
  setListMachines,
  setListTypeChats,
  setWebModeLogin,
} from "../../redux/general/action";
import defaultConfigsMainChat, {
  KEYS_MAIN_CHAT,
  LIST_TYPE_CHAT,
} from "../../services/chat/defaultConfigsMainChat";
import { useGetConfigsMainChat, useGetIsLogged } from "../../hooks/smallHooks";
import MenuTopBar from "../../components/Menus/MenuTopBar";
import ChatMain from "../../components/Pages/PageChat/Chats/ChatMain/ChatMain";
import InputMainChat from "../../components/Pages/PageChat/InputMainChat/InputMainChat";
import GroupLogin from "../../components/Login/GroupLogin";
import { PreviewAttachmentFiles } from "../../components/Pages/PageChat/ListAttachmentFiles/PreviewAttachmentFiles";
import { getAssistantsPreview } from "../../api/assistants";
import { getChannelJobStatus, getModels } from "../../api";
import { PageChatI18n } from "../../components/Add-ons/PageChat.i18n";
import useTranslation from "../../hooks/useTranslation";
import {
  DocumentRepository,
  makeDocumentId,
} from "../../services/indexedDB/document-repository";
import { useQuery } from "react-query";
import { CompressedAttachmentDTO } from "../../@DTO/embeddings";
import { showErrorNotification } from "../../services/notifications";
import COLORS from "../../utils/constants/colors";
import { ChatWebSocketProvider } from "../../contexts/ChatWebscoketContext";

const defaultMachine = {
  id: "openai/gpt-3.5-turbo",
  name: "OpenAI: GPT-3.5 Turbo",
  description:
    "GPT-3.5 Turbo is OpenAI's fastest model. It can understand and generate natural language or code, and is optimized for chat and traditional completion tasks.\n\nTraining data up to Sep 2021.",
  architecture: {
    modality: "text->text",
    tokenizer: "GPT",
    instruct_type: null,
  },
};

const Chat = () => {
  const dispatch = useDispatch();
  const configsMainChat = useGetConfigsMainChat();
  const {
    openLeftBar,
    openLogin,
    listAssistants,
    listMachines,
    listTypeChats,
    // @ts-ignore
  } = useSelector(state => state.configsGeneralReducer);
  const isLogged = useGetIsLogged();

  const { t } = useTranslation(PageChatI18n);

  const documentRepository = new DocumentRepository();

  const { data: attachments } = useQuery<CompressedAttachmentDTO[]>(
    ["attachments", configsMainChat?.chatData?._id],
    async () => getChannelJobStatus(configsMainChat?.chatData?._id),
    {
      enabled: configsMainChat?.chatData?._id !== undefined,
      staleTime: Infinity,
      refetchInterval: data => {
        const hasDocumentProcessing = data?.some(job => !job.processed);

        return hasDocumentProcessing ? 8000 : false;
      },
      onSuccess: async attachments => {
        attachments?.forEach(async attachment => {
          if (attachment?.processed && attachment?.document) {
            const documentId = makeDocumentId(
              attachment?.channel_id,
              attachment?.user_id,
              attachment?._id
            );

            await documentRepository.saveDocument(
              documentId,
              attachment?.document
            );
          }
        });
      },
      onError: (error: any) => {
        console.error("Error on get attachments", error);
        showErrorNotification(error?.message || "Error on get attachments");
      },
    }
  );

  // Função para carregar assistentes
  const loadAssistants = useCallback(async () => {
    try {
      const res = await getAssistantsPreview();
      dispatch(setListAssistants(res.aizzyAssistants));
    } catch (error) {
      console.error("Erro ao buscar assistentes:", error);
    }
  }, [dispatch]);

  // Função para carregar modelos
  const loadModels = useCallback(async () => {
    if (isLogged) {
      try {
        const res = await getModels();
        dispatch(setListMachines(res.data));
      } catch (error) {
        console.error("Erro ao buscar modelos:", error);
      }
    } else {
      dispatch(setListMachines([defaultMachine]));
    }
  }, [dispatch, isLogged]);

  // Função para configurar o chat principal
  const initializeConfigsMainChat = useCallback(() => {
    if (
      !localStorage.getItem("configsMainChat") &&
      listAssistants &&
      listMachines
    ) {
      const initialConfig = defaultConfigsMainChat({
        [KEYS_MAIN_CHAT.assistant]: listAssistants[0],
        [KEYS_MAIN_CHAT.machine]: defaultMachine?.id,
        [KEYS_MAIN_CHAT.chatData]: null,
        [KEYS_MAIN_CHAT.typeChat]: listTypeChats[0],
        [KEYS_MAIN_CHAT.enableWebSearch]: false,
      });

      localStorage.setItem("configsMainChat", JSON.stringify(initialConfig));
      dispatch(setConfigsMainChat(localStorage.getItem("configsMainChat")));
    }
  }, [dispatch, listAssistants, listMachines, listTypeChats]);

  // Função para configurar o modo de login da Web
  const initializeWebModeLogin = useCallback(() => {
    const webModeLogin = localStorage.getItem("webModeLogin") || "Web 2";
    dispatch(setWebModeLogin(webModeLogin));
  }, [dispatch]);

  // useEffect para carregar dados iniciais e configurar o estado do chat
  useEffect(() => {
    loadAssistants();
    loadModels();
    dispatch(setListTypeChats(LIST_TYPE_CHAT));
    initializeWebModeLogin();
  }, [dispatch, loadAssistants, loadModels, initializeWebModeLogin]);

  // useEffect para configurar as configurações do chat após os dados serem carregados
  useEffect(() => {
    initializeConfigsMainChat();
  }, [initializeConfigsMainChat]);

  return (
    <section className={`main-layout ${!openLeftBar ? "maximize" : ""}`}>
      {!configsMainChat?.chatData && <h1>{t("anonymously")}</h1>}
      <MenuTopBar />
      <ChatWebSocketProvider>
        <ChatMain />
        <div className="input-main-chat">
          <PreviewAttachmentFiles />
          <div
            className="gap-2 d-flex flex-column opened-chat"
            style={{ background: COLORS.gray100, borderRadius: 16 }}
          >
            <InputMainChat />
          </div>
        </div>
      </ChatWebSocketProvider>

      <GroupLogin isShow={openLogin} />
    </section>
  );
};

export default Chat;
