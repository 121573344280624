import { useEffect, useState } from "react";
import AssistantsTopPicks from "../AssistantsTopPicks";
import ListAssistantsAizzy from "../ListAssistantsAizzy";
import ListaAssistantsCommunity from "../ListaAssistantsCommunity";
import ListMyAssistants from "../ListMyAssistants";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Hits, InstantSearch, SearchBox } from "react-instantsearch-dom";
import searchClient from "../../../../services/algolia";
import CardAssistantsNewLayout from "../../../Cards/CardAssistantsNewLayout";
import ModalAssistantInfo from "../../../Modals/ModalInfoAssistants";
import useTranslation from "../../../../hooks/useTranslation";
import { PageAssistantsI18n } from "./HeaderPageAssistants.i18n";

const HeaderPageAssistants = ({onClick}) => {
  const [activeTab, setActiveTab] = useState(
    () => localStorage.getItem("assistant-tab") || "top"
  );
  const [renderAizzyTab, setRenderAizzyTab] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const { t } = useTranslation(PageAssistantsI18n);

  const handleCardClick = assistant => {
    setSelectedAssistant(assistant);
    setOpenModalInfo(true);
  };

  const objectAssistant = {
    _id: selectedAssistant?.objectID,
    category: selectedAssistant?.category,
    enabled: selectedAssistant?.enabled,
    name: selectedAssistant?.name,
    language: selectedAssistant?.language,
    role: selectedAssistant?.role,
    about: selectedAssistant?.about,
    profileImage: selectedAssistant?.profileImage,
    userId: selectedAssistant?.userId,
    userName: selectedAssistant?.userName,
    likes: selectedAssistant?.likes,
    userPrice: selectedAssistant?.userPrice,
    defaultGreetings: selectedAssistant?.defaultGreetings,
    likedby: selectedAssistant?.likedby,
    private: selectedAssistant?.private,
    hired: selectedAssistant?.hired,
  };

  const tabs = [
    {
      id: "top",
      label: t("tabs.top"),
      content:
        renderAizzyTab && searchTerm < 1 ? (
          <ListAssistantsAizzy />
        ) : (
          <AssistantsTopPicks
            renderAizzyTabProp={() => {
              setRenderAizzyTab(true);
              setActiveTab("aizzy");
              setRenderAizzyTab(false);
            }}
          />
        ),
    },
    {
      id: "aizzy",
      label: t("tabs.aizzy"),
      content: searchTerm < 1 && <ListAssistantsAizzy />,
    },
    {
      id: "commmunity",
      label: t("tabs.community.title"),
      content: searchTerm < 1 && <ListaAssistantsCommunity />,
    },
    {
      id: "myAssiistants",
      label: t("tabs.my.title"),
      content: searchTerm < 1 && <ListMyAssistants onClick={onClick}/>,
    },
  ];

  const HitComponent = ({ hit }) => {
    return (
      <CardAssistantsNewLayout
        setOpenModalInfo={() => handleCardClick(hit)}
        key={hit.index}
        direction="vertical"
        image={hit.profileImage}
        title={hit.name}
        description={hit.role}
        icon={hit.profileImage}
        name={hit.userName}
        likes={hit.likes}
      />
    );
  };

  useEffect(() => {
    localStorage.setItem("assistant-tab", activeTab);
  }, [activeTab]);

  return (
    <div style={{ width: "100%", maxWidth: 700 }}>
      <div
        style={{ paddingTop: 40, gap: 32 }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
          <h2>{t("title")}</h2>
          <span className="text-center">
            {t("paragraph")}
          </span>
        </div>
        <div className="w-100 rounded-4 d-flex flex-column align-items-center gap-3 page-assistants-algolia">
          <InstantSearch indexName="employees" searchClient={searchClient}>
            <SearchBox
              onChange={e => setSearchTerm(e.target.value)}
              className="w-100"
            />
            {searchTerm.length >= 1 ? (
              <Hits className="w-100 mb-4" hitComponent={HitComponent} />
            ) : (
              <Swiper
                className=" w-100 mb-4 d-flex overflow-visible"
                spaceBetween={8}
                breakpoints={{
                  0: {
                    slidesPerView: "auto",
                  },
                  992: {
                    slidesPerView: "auto",
                  },
                }}
              >
                {tabs?.map(tab => (
                  <SwiperSlide
                    style={{ width: "auto" }}
                    type="button"
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    <button
                      className={`tab-button one-line ${
                        activeTab === tab.id ? "active" : ""
                      } ${tab.id === "myAssiistants" ? "text-center" : ""}`}
                    >
                      <i className={tab.icon} />
                      {tab.label}
                    </button>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            {searchTerm < 1 &&
              tabs?.map(
                tab =>
                  tab.id === activeTab && (
                    <div className="w-100" key={tab.id}>
                      {tab.content}
                    </div>
                  )
              )}
          </InstantSearch>
        </div>
      </div>
      <ModalAssistantInfo
        isOpen={openModalInfo}
        toggleModal={() => {
          setOpenModalInfo(false);
        }}
        assistant={objectAssistant}
      />
    </div>
  );
};

export default HeaderPageAssistants;
